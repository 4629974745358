import 'focus-visible';
import header from './modules/header';
import lazyImages from './modules/lazyImages';
import main from './modules/main';
import popup from './modules/popup';
import yandex from './modules/yandex';
import svg4everybody from 'svg4everybody';
import documentReady from './helpers/documentReady';

documentReady(() => {
    lazyImages();
    header();
    main();
    popup();
    yandex();
    svg4everybody();
});
