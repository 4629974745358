export default () => {
    const yMap = document.querySelector('.map');

    if (yMap) {
        ymaps.ready(function () {
            var myMap = new ymaps.Map(
                'map',
                {
                    center: [51.99395857210095, 47.792439499999944],
                    zoom: 16,
                    controls: ['zoomControl', 'fullscreenControl'],
                    behaviors: [
                        'drag',
                        'dblClickZoom',
                        'rightMouseButtonMagnifier',
                        'multiTouch',
                    ],
                },
                {
                    searchControlProvider: 'yandex#search',
                }
            );

            myMap.geoObjects.add(
                new ymaps.Placemark(
                    [51.99395857210095, 47.792439499999944],
                    {
                        // Чтобы балун и хинт открывались на метке, необходимо задать ей определенные свойства.
                        balloonContentHeader: 'ООО "ПКФ РЭМЗ"',
                        balloonContentBody:
                            'г. Балаково, ул. Саратовское шоссе, д. 11А',
                        balloonContentFooter:
                            'Телефон: <a href="88453661374">8 (8453) 66-13-74</a>, <br> E-mail: <a href="mailto:mail@remz-balakovo.ru">mail@remz-balakovo.ru</a>',
                        hintContent: 'ООО "ПКФ РЭМЗ"',
                    },
                    {
                        // Опции.
                        // Необходимо указать данный тип макета.
                        iconLayout: 'default#image',
                        // Своё изображение иконки метки.
                        iconImageHref:
                            '/wp-content/themes/remz/assets/files/icons/svg/icon--pin.svg',
                        // Размеры метки.
                        iconImageSize: [52, 52],
                        // Смещение левого верхнего угла иконки относительно
                        // её "ножки" (точки привязки).
                        iconImageOffset: [-25, -58],
                    }
                )
            );
        });
    }
};
