import $ from 'jquery';
import Inputmask from 'inputmask';
import masonry from 'masonry-layout';
import imagesLoaded from 'imagesLoaded';

export default () => {
    $(function () {
        $('.header-search input').on('keyup', function () {
            var search = $(this).val();
            if (search.length < 3) {
                return false;
            }
            var data = {
                s: search,
                action: 'search_ajax',
                nonce: search_form.nonce,
            };
            $.ajax({
                url: search_form.ajaxurl,
                data: data,
                type: 'POST',
                dataType: 'json',
                beforeSend: function (xhr) {
                    $('.header-search-input-loader').show();
                    $('.icon--close').hide();
                    console.log('Ищем...');
                },
                success: function (data) {
                    if (data.out) {
                        $('.header-search-result').show().html(data.out);
                    }
                },
                error: function () {
                    console.log('error', arguments);
                },
                complete: function (xhr) {
                    function hideLoader() {
                        $('.header-search-input-loader').hide();
                        $('.icon--close').show();
                    }

                    setTimeout(hideLoader, 500);
                },
            });
            console.log(search);
        });

        $('.icon--close').on('click', function () {
            $(this).hide();
            $('.header-search-result').hide();
            $('.header-search input').empty().val('');
        });

        $(document).on('mouseup', function (e) {
            // событие клика по веб-документу
            var div = $('.header-search'); // тут указываем ID элемента
            if (
                !div.is(e.target) && // если клик был не по нашему блоку
                div.has(e.target).length === 0
            ) {
                // и не по его дочерним элементам
                // $('.search_form_clear').hide();
                $('#search_form_result').hide();
                $('#search_form_result').empty();
                // $('.searchform input[name="s"]').val('').change();
            }
        });
    });

    const tel = new Inputmask('+7 (999) 999-99-99');
    tel.mask(document.querySelectorAll('input[type="tel"]'));

    // init Masonry
    const grid = document.querySelector('.grid');

    if (grid) {
        var msnry = new masonry(grid, {
            itemSelector: '.grid-item',
            percentPosition: true,
            columnWidth: '.grid-sizer',
        });
        // layout Masonry after each image loads
        // msnry.imagesLoaded().progress(function () {
        //     msnry.masonry();
        // });

        imagesLoaded(grid).on('progress', function () {
            // layout Masonry after each image loads
            msnry.layout();
        });
    }
};
